<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6" md="4" v-if="!id_aluno"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_aluno"><v-select hide-details label="Turma:" :items="selectTurma" v-model="registro.id_turma" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Responsável:" :items="selectResponsavel" v-model="registro.id_responsavel" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Nome completo:" v-model="registro.nome_completo" /></v-col>
                            <v-col cols="12" sm="6" md="4" v-if="!id_aluno"><v-text-field hide-details label="* CPF:" v-model="registro.cpf" v-mask="'###.###.###-##'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Sexo:" :items="[{value : 'm', text : 'Masculino'}, {value : 'f', text : 'Feminino'}]" v-model="registro.sexo" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Data de nascimento:" v-model="registro.data_nascimento" v-mask="'##/##/####'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-select hide-details label="Estado civil:" :items="selectEstadoCivil" v-model="registro.id_estado_civil" clearable /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* CEP:" v-model="registro.cep" v-mask="'#####-###'" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Estado:" :items="selectEstado" v-model="registro.id_estado" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-autocomplete hide-details label="* Cidade:" :items="selectCidade" v-model="registro.id_cidade" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Bairro:" v-model="registro.bairro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Logradouro:" v-model="registro.logradouro" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Número:" v-model="registro.numero" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* E-mail:" v-model="registro.email" type="email" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="Complemento:" v-model="registro.complemento" /></v-col>
                            <v-col cols="12" sm="6" md="4"><v-text-field hide-details label="* Celular:" v-model="registro.celular" v-mask="['(##)  ####-####', '(##) #.####-####']" /></v-col>
                            <v-col cols="12"><v-textarea hide-details label="Observações:" v-model="registro.observacao" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

export default {
    name: "AlunoForm",
    components: {SelectFranquia},
    props : ['id_aluno'],
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectGrupo : [],
            selectEstado : [],
            selectCidade : [],
            selectTurma : [],
            selectResponsavel : [],
            selectEstadoCivil : []
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioSessao'])
    },
    methods : {
        getAluno() {
            axios.post(`${this.apiUrl}aluno/get`, {id_aluno : parseInt(this.id_aluno)}).then((res) => {
                if(res.data.erro) {
                    this.$router.push({path : '/aluno'})
                }
                this.registro = {
                    ...res.data,
                    data_nascimento : this.dataPt(res.data.data_nascimento)
                }
            })
        },
        selectCidadeListar() {
            if(this.registro.id_estado) {
                return axios.post(`${this.baseUrl}endereco/cidade_listar`, {id_estado : parseInt(this.registro.id_estado)}).then((res) => {
                    this.selectCidade = res.data.lista.map((c) => {return {
                        value : parseInt(c.id_cidade),
                        text : c.cidade
                    }})
                })
            } else {
                this.selectCidade = []
            }
        },
        selectTurmaListar() {
            if(this.registro.id_franquia) {
                return axios.post(`${this.apiUrl}turma/listar`, {id_franquia : parseInt(this.registro.id_franquia), limit : 9999}).then((res) => {
                    this.selectTurma = res.data.lista.map((t) => {return {
                        value : parseInt(t.id_turma),
                        text : t.turma
                    }})
                })
            } else {
                this.selectTurma = []
            }
        },
        selectEstadoListar() {
            return axios.post(`${this.baseUrl}endereco/estado_listar`).then((res) => {
                this.selectEstado = res.data.lista.map((rt) => {
                    return {value : parseInt(rt.id_estado), text : rt.estado}
                })
            })
        },
        selectResponsavelListar() {
            return axios.post(`${this.baseUrl}responsavel/listar`, {meu : true, limit:99999}).then((res) => {
                let r = res.data.lista.map((rt) => {
                    return {value : parseInt(rt.id_responsavel), text : rt.cnpj_cpf + ' - ' + rt.razao_social_nome_completo}
                })
                if(!this.id_aluno) {
                    r.unshift({value : -1, text : 'Cadastrar responsável com os dados do aluno'})
                } else {
                    r.unshift({value : this.registro.id_responsavel, text : this.registro.responsavel_razao_social_nome_completo})
                }
                this.selectResponsavel = r
            })
        },
        selectEstadoCivilListar() {
            return axios.post(`${this.baseUrl}responsavel/estado_civil_listar`).then((res) => {
                this.selectEstadoCivil = res.data.lista.map((rt) => {
                    return {value : parseInt(rt.id_estado_civil), text : rt.estado_civil}
                })
            })
        },
        salvar() {
            if(!this.id_aluno) {
                axios.post(`${this.apiUrl}aluno/novo`, {...this.registro, cep : this.mascaraRemover(this.registro.cep), cpf : this.mascaraRemover(this.registro.cpf), celular : this.mascaraRemover(this.registro.celular), data_nascimento : this.dataEn(this.registro.data_nascimento)}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/aluno/gerenciar/' + res.data})
                    }
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            } else {
                axios.post(`${this.apiUrl}aluno/editar`, {...this.registro, cep : this.mascaraRemover(this.registro.cep), celular : this.mascaraRemover(this.registro.celular), data_nascimento : this.dataEn(this.registro.data_nascimento)}).then( (res) => {
                    if(res.data.erro == true) {
                        this.erro = res.data.mensagem
                    } else {
                        this.erro = null
                        this.$router.push({path : '/aluno/gerenciar/' + this.id_aluno})
                    }
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
            }
        },
        async init() {
            this.registro = {}
            if(this.id_aluno) {
                await this.getAluno()
            }
            await this.selectEstadoCivilListar()
            await this.selectEstadoListar()
            await this.selectResponsavelListar()
        }
    },
    watch : {
        'registro.id_estado' : function () {
            this.selectCidadeListar().then((res) => {
                if(res.filter(v => v.value == this.registro.id_cidade).length == 0) {
                    this.registro.id_cidade = null
                }
            })
        },
        'registro.id_franquia' : function () {
            this.selectTurmaListar().then(() => {
                this.registro.id_turma = null
            })
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
